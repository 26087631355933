<template>
  <b-card title="List PH" sub-title="List of Product Hierarchy used as selection in Expiring/Expired Warranty Report">
    <b-modal v-model="modalDelete" title="Delete Product Ph">
    <!-- Alert before Delete-->
      <b-container>
        <b-row>
          <b-col cols="12" >
            <div class="d-flex">
              Are you sure want to delete this {{selectPh.phNumber}} ?
            </div>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-center align-items-center">
          <b-button variant="danger" class="ml-1" @click="confirmDelete">Confirm</b-button>
        </div>
      </template>
    </b-modal>
    <!-- End of Modal Needed on this page-->
    <b-col cols="12">
      <b-row>
        <b-col cols="12" md="3" class="hide-on-mobile" style="margin-bottom:15px">
          <b-btn
          v-if="permission.add"
          variant="success"
          :to="{name:'master-ph-add'}"
          >
            Add Product PH
          </b-btn>
        </b-col>
        <b-col class="hide-on-desktop" style="margin-bottom:15px">
          <b-btn
          v-if="permission.add"
          variant="success"
          :to="{name:'master-ph-add'}"
          >
            Add Product PH
          </b-btn>
        </b-col>
        <b-col style="margin-bottom:15px" md="4">
          <b-form-input
            v-model="searchProductHierarchy"
            type="search"
            placeholder="Search PH Number"
          />
        </b-col>
        <b-col md="2" style="margin-bottom:15px">
          <b-btn variant="primary" @click="searchByPh()">
            Search
          </b-btn>
        </b-col>

        <b-col cols="12" class="table-responsive">
          <b-table
            :items="productHierarchy"
            :fields="fields"
            :busy.sync="tableLoading"
            show-empty
          >
            <template #cell(createdAt)="row">
              {{dates(row.item.createdAt)}}
            </template>
            <template #cell(updatedAt)="row">
              {{dates(row.item.updatedAt)}}
            </template>
            <template #cell(skipScan)="row">
              {{row.item.skipScan||"false"}}
            </template>
            <template #cell(actions)="row">
              <b-dropdown  id="dropdown-dropleft" variant="primary" size="sm" text="Menu" no-caret>
                <template #button-content>
                  <feather-icon size="1x" icon="MenuIcon" />
                </template>
                <div class="dropdown-show">
                  <b-dropdown-item style="text-align:center">
                    <b-button v-if="permission.edit" @click="editProductHierarchy(row)" variant="outline-primary" class="mr-1">
                      <feather-icon icon="EditIcon"/>
                    </b-button>
                  </b-dropdown-item>
                  <b-dropdown-item style="text-align:center">
                    <b-button v-if="permission.delete" @click="deleteProductHierarchy(row.item)" variant="danger" class="mr-1">
                      <feather-icon icon="Trash2Icon"/>
                    </b-button>
                  </b-dropdown-item>
                </div>
              </b-dropdown>
            </template>
          </b-table>
        </b-col>

        <b-col cols="12">
          <b-pagination
            :total-rows="totalRows"
            :per-page="perPage"
            @change="onPageChange"
            v-model="currentPage"
            style="float: right"
          />
        </b-col>
      </b-row>
    </b-col>
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import axios from '@/axios';
import {userAccess,dateFormat} from '@/utils/utils'

export default {
  data() {
    return {
      fields: [
        { key: 'phNumber', label: 'Product Hierarchy Number', filterable: true, sortable: true },
        { key: 'word', label: 'Word', filterable: true, sortable: true },
        { key: 'createdAt', label: 'CreatedAt', filterable: true, sortable: true },
        { key: 'updatedAt', label: 'UpdatedAt', filterable: true, sortable: true },
        { key: 'actions', label: 'Actions' },
      ],
      search: '',
      searchPh: '',
      ph:[],
      itemCatalog:[],
      currentPage: 1,
      perPage: 10,
      totalRows: 1,
      tableLoading: false,
      modalDelete:false,
      donwloadModal:false,
      selectPh:'',
      searchProductHierarchy: "",
      productHierarchy: []
    }
  },
  mounted() {
    
  },
  methods: {
    searchByPh(){
      this.currentPage=1;
      this.getProductHierarchy({ 
        page: this.currentPage, 
        entry: this.perPage,
        ph: this.searchPh
      })
    },
    onPageChange(page) {
      this.getProductHierarchy({ 
        page: page, 
        entry: this.perPage,
        model: (this.search!="")?this.search:undefined,
        ph: (this.searchPh!="")?this.searchPh:undefined
      })
    },
    processMetadata(metadata) {
      let { totalData } = metadata;
      console.log('total data ',totalData)
      this.totalRows = totalData;
      this.tableLoading = false;
    },
    getProductHierarchy(filter) {
      axios.get(`sales-and-purchase/drcc/phmap`, { params: { ...filter } }).then((response) => {
        this.productHierarchy=response.data.data  
        this.processMetadata(response.data.metadata)
        return response.data;
      }).catch((e) => {
        console.log(e);
      })
    },
    editProductHierarchy(row) {
      this.$router.push(`/master/ph/edit/${row.item._id}`);
    },
    deleteProductHierarchy(row){
      this.modalDelete = true;
      this.selectPh = row;
    },
    confirmDelete() {
      this.modalDelete = false
      axios.delete(`/sales-and-purchase/drcc/phmap/${this.selectPh._id}`).then(() => {
        this.$bvToast.toast(`You have successfully deleted post code ${this.selectPh.phNumber}`, {
          title: "Success",
          variant: "success",
          solid: true,
        });
        this.getProductHierarchy({
          page: this.currentPage, 
          entry: this.perPage,
          word: this.word,
          ph: this.searchPh

        })
      }).catch((e) => {
        this.$bvToast.toast(`You have failed to delete postcode ${this.selectPh.phNumber}`, {
          title: "Fail",
          variant: "danger",
          solid: true,
        });
      })
    },
    dates(date) {
      console.info(date);
      return dateFormat(date);
    },
  },
  computed: {
    permission() {
      return userAccess("Master PH", "masterdata_menu");
    },
  },
  created() {
    document.title = 'Master PH | RSP';
    this.getProductHierarchy({
      page: this.currentPage, 
      entry: this.perPage,
    })
  },
}
</script>
<style scoped>
@media (min-width: 1423px) {
  .hide-on-mobile { display: none !important; }
  .show-on-desktop {display: inline !important;}
}

@media (max-width: 1422px) {
  .hide-on-desktop { display: none !important; }
}

.dropdown-show{
  overflow-y: auto;
  height: 75px;
}
</style>